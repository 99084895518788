<template>
  <h1>Welcome to Taylor Tops Network</h1>
  <h3>Home of Monorail Dawgs</h3>
  <img alt="Dawgs" src="../assets/dawg-forest.jpg">
  <img alt="Dawgs" src="../assets/dawg-scenic.jpg">
</template>


<script>
export default {
  name: 'TayTop',
  props: {
    msg: String
  }
}
</script>
