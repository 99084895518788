<template> 
  <!-- <TestWorld msg="Welcome to the mattylam11 fanpage!"/> -->
   <TayTop></TayTop>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import TestWorld from './components/TestWorld.vue'
import TayTop from './components/TayTop.vue';

export default {
  name: 'App',
  components: {
    // HelloWorld,
    // TestWorld,
    TayTop
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
